import { all, put, call } from 'redux-saga/effects'
import {getMenuData,getMenuDataforManagers} from 'services/menu'

export function* GET_DATA() {
  const usernameRole =localStorage.getItem('name')
  // console.log("userRole================================>",usernameRole)
  if(usernameRole==='Administrator'){
    // console.log("MenuData in if============>",menuData)
    const  menuData= yield call(getMenuData)
    // console.log("MenuData============>",menuData)
      yield put({
        type: 'menu/SET_STATE',
        payload: {
          menuData,
        },
      })
    }
    else {
      const menuData = yield call(getMenuDataforManagers)
      yield put({
        type: 'menu/SET_STATE',
        payload: {
          menuData,
        },
      })
    }
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
