export  async function getMenuData() {
  return [
    {
      title: 'Home',
      key: 'home',
      url: '/ofd/home',
      icon: 'fa fa-home',
    },
    {
      title: 'Pending Orders',
      key: 'pendingorders',
      url: '/ofd/pendingOrders',
      icon:'fa fa-tasks'
    },
    {
      title:'In Progress Orders',
      key:'inprogress',
      url:'/ofd/inprogressOrders',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'In Transit Orders',
      key: 'intransitorders',
      url: '/ofd/intransitOrders',
      icon:'fa fa-truck'
    },
    {
      title: 'All Orders',
      key: 'allorders',
      url: '/ofd/allOrders',
      icon:'fa fa-th-large'
    },
    // {
    //   title: 'Reports',
    //   key: 'reports',
    //   url: '/ofd/reports',
    //   icon:'fa fa-bar-chart'
    // },
  ]
}

export  async function getMenuDataforManagers() {
  return [
    // {
    //   title: 'Home',
    //   key: 'home',
    //   url: '/ofd/home',
    //   icon: 'fa fa-home',
    // },
    {
      title: 'Pending Orders',
      key: 'pendgorders',
      url: '/manager/pendingOrders',
      icon:'fa fa-tasks'
    },
    {
      title:'In Progress Orders',
      key:'inprogressord',
      url:'/manager/inprogressOrders',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'In Transit Orders',
      key: 'intransitordss',
      url: '/manager/intransitOrders',
      icon:'fa fa-truck'
    },
    {
      title: 'All Orders',
      key: 'allordersss',
      url: '/manager/allOrders',
      icon:'fa fa-th-large'
    },
    // {
    //   title: 'Reports',
    //   key: 'reports',
    //   url: '/ofd/reports',
    //   icon:'fa fa-bar-chart'
    // },
  ]
}